import React from "react";
// import Footer from "../components/layout/footer/Footer";
import Navbar from "../components/layout/header/Navbar";
import Layout from "../components/layout/Layout";
import PageHeader from "../components/team/PageHeader";
import Refund from "../components/others/Refund";

export default function RefundPolicy() {
  return (
    <Layout>
      {/* <Navbar darkBg /> */}
      <PageHeader HeaderTitle="Pricing & Refund Policy" />
      {/* <Accordion Title /> */}
      <Refund Title space />
      {/* <Footer /> */}
    </Layout>
  );
}
