import React from "react";

export default function DeleteAccount({ Title, space }) {
  return (
    <>
      <section className={`gray-light-bg  ${space ? "ptb-100" : "pb-60"}`}>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-9 col-lg-8">
              <div className="section-heading mb-5 text-center">
                <h2 style={{ color: "red" }}>Delete Account</h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="single-faq">
                <p>
                  For delete your account{" "}
                  <a href="https://forms.gle/9rtod46grdJ8QuB66"> Click Here.</a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
